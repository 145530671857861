import Vue from 'vue'
import App from './App.vue'
// https://github.com/bevacqua/correcthorse/blob/master/wordlist.json

// Vue.config.productionTip = false


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Vue.config.devtools = true

new Vue({
  render: h => h(App),
}).$mount('#app')
